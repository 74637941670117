import { mapActions } from 'vuex'

export default { 
	name: 'Index',
  data () {
    return {
        headers: [
            { text: 'ID', value: 'integration_item_id'},
            { text: 'Integração', value: 'name'},
            { text: 'Direção', value: 'direction_integration', align: 'center', sortable: false},
            { text: 'Status', value: 'active', type: 'boolean'},
            { text: 'Ações', value: 'actions', sortable: false}
        ],
      params: {},
      meta: {},
      collections: [],
      itemDelete: {},
      model: { active: false },
      loading: false,
      message: '',
      error: false,
      direction: {
        U: { icon: 'mdi-arrow-up-bold-box',   color: 'green' },
        D: { icon: 'mdi-arrow-down-bold-box', color: 'primary' }
      },
      errors: {},
      text: '',
      show: false,
      showForm: false,
      deleting: false,
      updating: false
    }
	},
  methods: {
    ...mapActions('integration', ['ActionIndexInstitutionIntegrationItems', 'ActionDestroyInstitutionIntegrationItem']),
    
    getData() {

      this.error = false;
      this.message = '';
      this.loading = true;
      this.showForm = false;

      let payload = {};

      Object.assign(payload, this.params);

      payload.with = 'integrationItem';
      payload.filter_relations = [
        {
            name:   'integrationItem',
            key:    'active', 
            value:  1,
        }
    ];

      this.ActionIndexInstitutionIntegrationItems(payload)
        .then((r) => {
          this.collections = r.data
          this.meta = r.meta
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteItem() {
      this.deleting = true;
      this.message = '';

      this.ActionDestroyInstitutionIntegrationItem({ id: this.itemDelete.id })
          .then((res) => {
            this.message = res.message;
            this.show = false;
            this.getData();
          })
          .catch((error) =>{
            this.message = error.message;
            this.error = true;
          })
          .finally(() => {
            this.deleting = false;
          });
    },
  },
  watch: {

    params(params_new, params_old){
      if(params_new != params_old) {
        this.getData()
      }
    }, //Função para tratar quando um item for exibido para deletar
    itemDelete(item_new, item_old){
      if(item_new !== item_old && Object.keys(item_new).length > 0)
      {
        this.show = true
        this.text = "Deseja excluir o item?"
      } else {
        this.show = false
        this.text = ''
      }
    },

    show(show_new, show_old) {

      if(show_new != show_old && !show_new) {
        this.itemDelete = {};
      }
    },

    model(item_new, item_old){

      if(item_new !== item_old && (this.model && this.model.id))
      {
        this.titleForm = 'Editar'
        this.showForm = true
        this.errors = {}

      } else if(Object.keys(this.model).length === 0){

        this.titleForm = 'Cadastrar';
        this.showForm = false;
        this.errors = {};
      }
    },

    showForm(show_new, show_old) {
      if(show_new != show_old && !show_new) {
        this.model = { active: false };
      }
    },
  },
} 